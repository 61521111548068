import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
// import router from '../../src/router'

/* eslint-disable */
export default {
  state: {
    questions: [],
    points: [],
    markers: [],
    mapParameters: "",
    imageUrl: "",
    gameData: "",
    sentence: "",
    welcomePageData: "",
    loading: false,
    results: [],
    questionsList: "",
    lang: "fr",
    users: [],
    evolutionPointNumber: "",
    arrayOfAudioUrl: [],
    arrayOfPointInfo: [],
    arrayOfImagesBase64: [],
    questionsOrder: "",
    questionsArray: [],
  },
  mutations: {
    switchLang(state, payload) {
      console.log("[switchLang] payload", payload);
      state.lang = payload;
    },
    setQuestionList(state, payload) {
      // //console.log("[setQuestionList] payload", payload)
      state.questionsList = payload;
    },
    addResult(state, payload) {
      state.results.push(payload);
    },
    setMapParameters(state, payload) {
      state.mapParameters = payload;
    },
    setWelcomePage(state, payload) {
      // //console.log('[mutations] setWelcomePage payload', payload)
      state.welcomePage = payload;
    },
    setLettersArray(state, payload) {
      state.lettersArray = payload;
    },
    setGameParameters(state, payload) {
      state.gameParameters = payload;
    },
    setAllNavGameNames(state, payload) {
      state.allNavGameNames = payload;
    },
    setQuestionsArray(state, payload) {
      state.questionsArray = payload;
    },
    setUser(state, payload) {
      // //console.log("[setUser] payload", payload)
      state.user = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
      //console.log("[mutations] setLoading => state.loading", state.loading);
    },
    setPointsArray(state, payload) {
      // //console.log('[setPointsArray] payload', payload)
      state.pointsArrayOrder = payload;
    },
    setPoints(state, payload) {
      state.points = payload;
    },
    setMarkers(state, payload) {
      state.markers = payload;
    },
    setQuestions(state, payload) {
      state.questions = payload;
    },
    setQuestionsSuffled(state, payload) {
      state.questionsShuffled = payload;
    },
    addPoint(state, payload) {
      //console.log("addpoint", payload);
      state.points.push(payload);
    },
    addMarker(state, payload) {
      state.markers.push(payload);
    },
    setEvolutionPoint(state, payload) {
      state.evolutionPointNumber = payload;
    },
    // addUser(state, payload) {
    //     //console.log("[addUser] payload", payload)
    //     state.users.push(payload)
    // },
    addUserListArray(state, payload) {
      state.users = payload;
    },
    setArrayOfAudioUrl(state, payload) {
      state.arrayOfAudioUrl = payload;
    },
    setArrayOfPointInfo(state, payload) {
      state.arrayOfPointInfo = payload;
    },

    setArrayOfImagesBase64(state, payload) {
      state.arrayOfImagesBase64 = payload;
    },
    // setResults(state, payload) {
    //     state.allResults = payload
    // }
  },
  actions: {
    sendComment({}, payload) {
      const db = firebase.database();
      db.ref(payload.navigationName + "/comments/").push(payload);
    },
    sendEmail({}, payload) {
      const db = firebase.database();
      db.ref(payload.navigationName + "/emails/").push(payload.email);
    },
    addEvolutionToDB({ commit }, payload) {
      const db = firebase.database();
      let pointNumber = payload.pointNumber;
      commit("setEvolutionPoint", pointNumber);

      /////////////////////////    HERE I CHANGED TO SET({pointNumber: payload.pointNumber}) to (pointNumber)
      db.ref(
        payload.navigationName + "/users/" + payload.userKey + "/evolution/"
      ).set(pointNumber);
    },
    checkResults({ getters, dispatch }, payload) {
      let userName = getters.user.userName;
      let navigationName = getters.gameParameters.navigationName;
      const db = firebase.database();
      var pad = function(num, size) {
          return ("000" + num).slice(size * -1);
        },
        time = parseFloat(
          payload.gameTimeInSecond + payload.errorNumber * 60
        ).toFixed(3),
        hours = Math.floor(time / 60 / 60),
        minutes = Math.floor(time / 60) % 60,
        seconds = Math.floor(time - minutes * 60);

      var totalTime =
        pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
      // db.ref('groups/results/' + userName).set(errors)
      var totalSeconds = payload.gameTimeInSecond + payload.errorNumber * 60;
      db.ref(navigationName + "/results/").push({
        errorNumber: payload.errorNumber,
        secondsToTime: payload.gameSecondToTime,
        totalSeconds: totalSeconds,
        totalTime: totalTime,
        userName: userName,
        NavigationEndTime: new Date().toString(),
      });
    },
    getResults({ dispatch, commit }, payload) {
      const db = firebase.database();
      db.ref(payload + "/results")
        .orderByChild("totalSeconds")
        .on("value", (resultsInfoOrderByChild) => {
          let index = 0;
          resultsInfoOrderByChild.forEach(function(results) {
            index++;
            var resultsOfTheGame = results.val();
            // //console.log("resultsOfTheGame ", resultsOfTheGame)
            commit("addResult", {
              resultsOfTheGame: resultsOfTheGame,
              place: index,
            });
          });
        });
    },
    storePointsOrder({}, payload) {
      const db = firebase.database();
      db.ref(payload.navigationName + "/pointsOrder/").push(
        payload.pointsOrder
      );
      // //console.log("[storePointsOrder] payload", payload)
    },
    storeMapParameters({}, payload) {
      const db = firebase.database();
      // //console.log("storeMapParameters => payload", payload)
      // if (payload.lat != '') {
      db.ref(payload.navigationName + "/mapParameters/lat").set(payload.lat);
      // }
      // if (payload.lng != '') {
      db.ref(payload.navigationName + "/mapParameters//lng").set(payload.lng);
      // }
      // if (payload.zoom != '') {
      db.ref(payload.navigationName + "/mapParameters//zoom").set(payload.zoom);
      // }
    },
    storeEndPoint({}, payload) {
      const db = firebase.database();
      // //console.log("storeEndPoint => payload", payload)
      db.ref(payload.navigationName + "/lastPoint").set(payload.endPoint);
    },
    createNewGame({ commit, getters }, payload) {
      // //console.log("[createNewGame] payload", payload)
      const db = firebase.database();
      db.ref(payload.navigationName).set(payload);
    },
    createNewPoint({ commit, getters }, payload) {
      commit("setLoading", true);
      const db = firebase.database();
      db.ref(
        payload.navigationName +
          "/" +
          getters.lang +
          "/points/" +
          payload.pointNumber
      )
        .set(payload)
        .then((_) => {
          // I stock the event's image in FB storage
          const filename = payload.image.name;
          // const ext = filename.slice(filename.lastIndexOf('.'))
          const ext = "png";
          return firebase
            .storage()
            .ref(
              payload.navigationName +
                "/points/" +
                payload.pointNumber +
                "." +
                ext
            )
            .put(payload.image);
        })
        .then((fileData) => {
          // imageUrl = fileData.metadata.downloadURLs[0]
          firebase
            .storage()
            .ref(
              payload.navigationName + "/points/" + payload.pointNumber + ".png"
            )
            .getDownloadURL()
            .then((imageUrl) => {
              this.imageUrl = imageUrl;
              return firebase
                .database()
                .ref(payload.navigationName + "/" + getters.lang + "/points")
                .child(payload.pointNumber)
                .update({
                  imageUrl: this.imageUrl,
                });
            })
            .then((_) => {
              return firebase
                .database()
                .ref(
                  payload.navigationName +
                    "/" +
                    getters.lang +
                    "/points/" +
                    payload.pointNumber +
                    "/pictures/" +
                    payload.pointNumber
                )
                .update({
                  imageUrl: this.imageUrl,
                });
            })
            .then((_) => {
              return firebase
                .database()
                .ref(
                  payload.navigationName +
                    "/" +
                    getters.lang +
                    "/points/" +
                    payload.pointNumber +
                    "/pictures/"
                )
                .once("value");
            })
            .then((data) => {
              this.pictures = data.val();
            });
          commit("addPoint", payload);
          commit("setLoading", false);
          // //console.log('createNewPoint - payload', payload)
        });
    },
    createNewQuestion({ commit, getters }, payload) {
      commit("setLoading", true);
      //  //console.log("[createNewQuestion] payload", payload);
      const db = firebase.database();
      db.ref(payload.navigationName + "/" + getters.lang + "/questions").push(
        payload.question
      );
      commit("setLoading", false);
    },
    updatQuestion({ commit, getters }, payload) {
      commit("setLoading", true);
      const db = firebase.database();
      db.ref(
        payload.navigationName +
          "/" +
          getters.lang +
          "/questions/" +
          payload.key
      ).update(payload.question);
      commit("setLoading", false);
    },
    updatePoint({ commit, getters }, payload) {
      //console.log("updatePoint - payload", payload);
      // commit('setLoading', true)
      const db = firebase.database();
      db.ref(
        payload.navigationName +
          "/" +
          getters.lang +
          "/points/" +
          payload.pointNumber
      ).update(payload);
      // commit('setLoading', false)
    },
    createNewMarker({ commit }, payload) {
      const db = firebase.database();
      // //console.log("[createNewMarker] payload", payload)
      commit("addMarker", payload.position);
      db.ref(payload.navigationName + "/markers/" + payload.pointNumber).set(
        payload.position
      );
    },
    createNewUser({ commit }, payload) {
      //console.log("createNewUser payload", payload);

      const db = firebase.database();
      let pointNumber = payload.pointNumber;
      var navigationHour = new Date().toString();
      if (localStorage.getItem("userKey")) {
        //console.log("userKey in localstorage");
        db.ref(
          payload.navigationName + "/users/" + localStorage.getItem("userKey")
        ).set({
          userName: payload.userName,
          NavigationTime: navigationHour,
          groupColorEnglish: payload.groupColorEnglish,
          evolution: parseInt(pointNumber),
        });
        commit("setUser", payload);
      } else {
        //console.log(
        //   "NNNNNOOOOOO  userKey in localstorage payload.navigationName",
        //   payload.navigationName
        // );
        db.ref(payload.navigationName + "/users")
          .push({
            userName: payload.userName,
            NavigationTime: navigationHour,
            groupColorEnglish: payload.groupColorEnglish,
          })
          .then((user) => {
            localStorage.setItem("userKey", user.key);
            //console.log("[.then after ocal stoage] user", user);
          });
        commit("setUser", payload);
      }
      // db.ref(payload.navigationName + '/users').push({
      //         userName: payload.userName,
      //         NavigationTime: navigationHour,
      //         groupColorEnglish: payload.groupColorEnglish
      //     })
      //     .then(user => {
      //         localStorage.setItem("userKey", user.key);
      //     })
      // commit('setUser',
      //     payload
      // )
    },

    //   ******************************* FETCH PART ******************************************
    fetchAllNavGames({ commit, dispatch, getters }) {
      commit("setLoading", true);
      firebase
        .database()
        .ref()
        .once("value")
        .then((data) => {
          // //console.log("[fetchAllNavGames] data", data.val())
          var namesOfNavGames = [];
          data.forEach((eachNavGame) => {
            namesOfNavGames.push(eachNavGame.key);
          });
          commit("setAllNavGameNames", namesOfNavGames);
          commit("setLoading", false);
        });
    },
    fetchNavtigationData({ commit, dispatch, getters }, payload) {
      commit("setLoading", true);

      firebase
        .database()
        .ref(payload)
        .once("value")
        .then((data) => {
          let navigationData = data.val();
          var gameParameters = {
            anniversary: navigationData.anniversary,
            infoPage: navigationData.infoPage,
            navigationName: navigationData.navigationName,
            randomQuestions: navigationData.randomQuestions,
            lastPoint: navigationData.lastPoint,
            pointsArrayOrder: navigationData.pointsOrder,
          };
          commit("setGameParameters", gameParameters);
          //console.log("[fetchNavtigationData] navigationData", navigationData);
          //console.log("[fetchNavtigationData] gameParameters", gameParameters);

          this.mapParameters = navigationData.mapParameters;
          // //console.log("[fetchNavtigationData] this.mapParameters", this.mapParameters)
          commit("setMapParameters", this.mapParameters);
          this.endPoint = navigationData.endPoint;
          commit("setLoading", false);
        });
      firebase
        .database()
        .ref(payload + "/" + getters.lang + "/questions")
        .once("value")
        .then((data) => {
          let questions = [];
          data.forEach((question) => {
            // //console.log("getters.lang", getters.lang)
            // //console.log("question.val()", question.val())
            questions.push({
              key: question.key,
              data: question.val(),
            });
            // questions.push()
          });
          commit("setQuestionList", questions);
        });
    },
    fetchConfirmationPhrase({ commit }, payload) {
      commit("setLoading", true);
      firebase
        .database()
        .ref(payload.navigationName)
        .once("value")
        .then((data) => {
          this.welcomePageData = data.val();
          // **************************FETCH GAME PARAMETERS
          var gameParameters = {
            welcomePhrase: this.welcomePageData.welcomePhrase,
            buttonStartGame: this.welcomePageData.buttonStartGame,
            welcomePhraseOne: this.welcomePageData.welcomePhraseOne,
            welcomePhraseTwo: this.welcomePageData.welcomePhraseTwo,
            welcomePhraseThree: this.welcomePageData.welcomePhraseThree,
            welcomePhraseFour: this.welcomePageData.welcomePhraseFour,
            welcomePhraseFive: this.welcomePageData.welcomePhraseFive,
          };
          commit("setWelcomePage", gameParameters);
        });
      commit("setLoading", false);
    },
    fetchPoints({ commit, getters }, payload) {
      // commit('setLoading', true)
      firebase
        .database()
        .ref(payload)
        .once("value")
        .then((data) => {
          switch (getters.lang) {
            case "en":
              this.points = data.val().en.points;
              console.log("English");

              break;
            case "fr":
              this.points = data.val().fr.points;
              console.log("Français");

              break;
            case "de":
              this.points = data.val().de.points;
              console.log("Deutsh");

              break;
            default:
          }
          // if (getters.lang == "fr") {
          //   this.points = data.val().fr.points;
          // } else {
          //   this.points = data.val().en.points;
          // }
          // //console.log("[fetchPoints] this.points", this.points)
          commit("setPoints", this.points);
          //******************** FETCH MARKERS - USED TO GET THE GPS COORDONATE IN THE ADMIN PAGE
          this.markers = data.val().markers;
          // console.log("[fetchPoints] this.markers", this.markers)
          commit("setMarkers", this.markers);
        });
      // commit('setLoading', false)
    },
    fetchUsersList({ commit }, payload) {
      const db = firebase.database();
      db.ref(payload + "/users")
        .orderByChild("evolution")
        .on("value", function(users) {
          var userListArray = [];
          users.forEach(function(child) {
            if (child.val().evolution != undefined) {
              userListArray.push({
                evolution: child.val().evolution,
                userName: child.val().userName,
              });
            }
          });
          let sortedUsersList = userListArray.sort((a, b) => {
            const evolutionA = a.evolution;
            const evolutionB = b.evolution;
            let comparison = 0;
            if (evolutionA > evolutionB) {
              comparison = 1;
            } else if (evolutionA < evolutionB) {
              comparison = -1;
            }
            return comparison * -1;
          });
          commit("addUserListArray", sortedUsersList);
        });
    },
    fetchGameData({ commit, dispatch, getters }, payload) {
      commit("setLoading", true);

      firebase
        .database()
        .ref(payload.navigationName)
        .once("value")
        .then((data) => {
          this.gameData = data.val();
          //  console.log("this.gameData = data.val()", this.gameData);
          // **************************FETCH GAME PARAMETERS
          var gameParameters = {
            anniversary: this.gameData.anniversary,
            infoPage: this.gameData.infoPage,
            navigationName: this.gameData.navigationName,
            randomQuestions: this.gameData.randomQuestions,
            lastPoint: this.gameData.lastPoint,
          };
          commit("setGameParameters", gameParameters);
          // console.log("gameParameters", gameParameters)
          // **************************FETCH POINTS
          switch (getters.lang) {
            case "en":
              this.points = data.val().en.points;
              console.log("data.val().en.points");

              break;
            case "fr":
              this.points = data.val().fr.points;
              console.log("data.val().fr.points");

              break;
            case "de":
              this.points = data.val().de.points;
              console.log("data.val().de.points");

              break;
            default:
          }
          // if (getters.lang == "fr") {
          //   this.points = data.val().fr.points;
          // } else {
          //   this.points = data.val().en.points;
          // }

          // console.log("[fetchPoints] this.points", this.points)
          commit("setPoints", this.points);
          // **************************FETCH MAP PARAMERTERS
          this.mapParameters = data.val().mapParameters;
          // console.log("[fetchPoints] this.mapParameters", this.mapParameters)
          commit("setMapParameters", this.mapParameters);
          // **************************FETCH MARKERS
          this.markers = data.val().markers;
          // console.log("[fetchPoints] this.markers", this.markers)
          commit("setMarkers", this.markers);
          // **************************FETCH POINTS ORDER && QUESTIONS
          var arraysOfPointsOrder = Object.values(data.val().pointsOrder);
          ///////////////////////ADDED FROM HBREW VERSION/////////////////
          if (localStorage.getItem("pointsArrayOrder")) {
            // var arraysOfPointsOrder = JSON.parse(localStorage.getItem("pointsArrayOrder"))
            // console.log('[setPointsArray] b4 commit locaol storage payload', payload)
            // commit('setPointsArray', arraysOfPointsOrder)
            var randomPointsOrder = JSON.parse(
              localStorage.getItem("pointsArrayOrder")
            );
            // console.log(
            //   "[setPointsArray] b4 commit locaol storage payload",
            //   payload
            // );
            commit("setPointsArray", randomPointsOrder);
          } else {
            /////////////////////////////////////////////////
            var randomPointsOrder =
              arraysOfPointsOrder[
                Math.floor(Math.random() * arraysOfPointsOrder.length)
              ];
            commit("setPointsArray", randomPointsOrder);
            localStorage.setItem(
              "pointsArrayOrder",
              JSON.stringify(randomPointsOrder)
            );
          }
          switch (getters.lang) {
            case "en":
              dispatch("checkQuestionListToBeSent", {
                questions: data.val().en.questions,
                randomPointsOrder: randomPointsOrder,
              });
              //   console.log("data.val().en.points");

              break;
            case "fr":
              dispatch("checkQuestionListToBeSent", {
                questions: data.val().fr.questions,
                randomPointsOrder: randomPointsOrder,
              });
              // console.log("data.val().fr.points");

              break;
            case "de":
              dispatch("checkQuestionListToBeSent", {
                questions: data.val().de.questions,
                randomPointsOrder: randomPointsOrder,
              });
              //     console.log("data.val().de.points");

              break;
            default:
          }
          // if (getters.lang == "fr") {
          //   dispatch("checkQuestionListToBeSent", {
          //     questions: data.val().fr.questions,
          //     randomPointsOrder: randomPointsOrder,
          //   });
          // } else {
          //   dispatch("checkQuestionListToBeSent", {
          //     questions: data.val().en.questions,
          //     randomPointsOrder: randomPointsOrder,
          //   });
          // }
        });
    },
    fetchGameDataFull({ commit, dispatch, getters }, payload) {
      commit("setLoading", true);
      firebase
        .database()
        .ref(payload.navigationName)
        .once("value")
        .then((data) => {
          this.gameData = data.val();
          // console.log("this.gameData = data.val()", this.gameData);
          // **************************FETCH GAME PARAMETERS
          var gameParameters = {
            // anniversary: this.gameData.anniversary,
            infoPage: this.gameData.infoPage,
            navigationName: this.gameData.navigationName,
            randomQuestions: this.gameData.randomQuestions,
            lastPoint: this.gameData.lastPoint,
            carousel: this.gameData.carousel,
            withVideo: this.gameData.withVideo,
            questionsOrder: this.gameData.questionsOrder,
            imagesList: this.gameData.imagesList,
          };
          commit("setGameParameters", gameParameters);
          // console.log("gameParameters", gameParameters);
          // **************************FETCH POINTS
          //*********************************************************************************************************** */
          switch (getters.lang) {
            case "en":
              this.points = data.val().en.points;
              console.log("English");

              break;
            case "fr":
              this.points = data.val().fr.points;
              console.log("Français");

              break;
            case "de":
              this.points = data.val().de.points;
              console.log("Deutsh");

              break;
            default:
          }
          // this.points = data.val().he.points;
          //   if (getters.lang == "fr") {
          //  console.log("[index] reloading language FR");
          // this.points = data.val().fr.points;
          //   } else {
          //     console.log("[index] reloading language AR");

          //     this.points = data.val().ar.points;
          //   }
          //*********************************************************************************************************** */

          // console.log("[fetchPoints] this.points", this.points);
          // CREATE TWO ARRAY OF POINNT INFORMATION AND AUDIO URL TO NOT BE DEPENDING ON THE POINT NUMBER
          var arrayOfAudioUrl = [];
          var arrayOfPointInfo = [];
          var arrayOfImagesBase64 = [];
          this.points.forEach(function(point) {
            arrayOfAudioUrl.push(point.audioUrl);
            arrayOfPointInfo.push(point.pointInformation);
            if (gameParameters.carousel || gameParameters.imagesList) {
              arrayOfImagesBase64.push(point.imageBase64);
            }
          });
          //    console.log("arrayOfAudioUrl", arrayOfAudioUrl);

          commit("setArrayOfAudioUrl", arrayOfAudioUrl);
          localStorage.setItem(
            "arrayOfAudioUrl",
            JSON.stringify(arrayOfAudioUrl)
          );
          commit("setArrayOfPointInfo", arrayOfPointInfo);
          localStorage.setItem(
            "arrayOfPointInfo",
            JSON.stringify(arrayOfPointInfo)
          );
          commit("setArrayOfImagesBase64", arrayOfImagesBase64);
          localStorage.setItem(
            "arrayOfImagesBase64",
            JSON.stringify(arrayOfImagesBase64)
          );

          commit("setPoints", this.points);
          // **************************FETCH MAP PARAMERTERS
          this.mapParameters = data.val().mapParameters;
          // console.log("[fetchPoints] this.mapParameters", this.mapParameters)
          commit("setMapParameters", this.mapParameters);
          // **************************FETCH MARKERS
          this.markers = data.val().markers;
          // console.log("[fetchPoints] this.markers", this.markers)
          commit("setMarkers", this.markers);
          // **************************FETCH WAYPOINTS
          if (data.val().wayPoints) {
            this.wayPoints = data.val().wayPoints;
            //   console.log("[fetchPoints] this.wayPoints", this.wayPoints)
            localStorage.setItem("wayPoints", JSON.stringify(this.wayPoints));
            commit("setWayPoints", this.wayPoints);
          }

          // **************************FETCH POINTS ORDER
          var arraysOfPointsOrder = Object.values(data.val().pointsOrder);
          if (localStorage.getItem("pointsArrayOrder")) {
            var randomPointsOrder = JSON.parse(
              localStorage.getItem("pointsArrayOrder")
            );
            // console.log('[setPointsArray] b4 commit locaol storage payload', payload)
            commit("setPointsArray", randomPointsOrder);
          } else {
            var randomPointsOrder =
              arraysOfPointsOrder[
                Math.floor(Math.random() * arraysOfPointsOrder.length)
              ];
            commit("setPointsArray", randomPointsOrder);
            localStorage.setItem(
              "pointsArrayOrder",
              JSON.stringify(randomPointsOrder)
            );
          }

          // **************************FETCH QUESTIONS
          switch (getters.lang) {
            case "en":
              var questions = data.val().en.questions;
              console.log("data.val().en.points");

              break;
            case "fr":
              var questions = data.val().fr.questions;
              console.log("data.val().fr.points");

              break;
            case "de":
              var questions = data.val().de.questions;
              console.log("data.val().de.points");

              break;
            default:
          }
          // if (getters.lang == "fr") {
          //   var questions = data.val().fr.questions;
          // } else {
          //   var questions = data.val().en.questions;
          // }
          if (
            this.gameData.questionsOrder == "sorted" ||
            this.gameData.questionsOrder == "linkedToPointNumber"
          ) {
            ////////// IF "SORTED", THEN NO RANDOM, ONLY PASS THE QUESTIONS AS APPEAR IN THE DATABASE.
            var questionArrayWihoutEmptyQuestion = [];
            var arrayQuestion = Object.values(questions);
            arrayQuestion.forEach((question) => {
              if (question != null) {
                questionArrayWihoutEmptyQuestion.push(question);
              } else {
                console.log("this question is empty", question);
              }
            });
            // //console.log(
            //   "QUESTIONS SORTED+++++++ questionArrayWihoutEmptyQuestion",
            //   questionArrayWihoutEmptyQuestion
            // );

            commit("setQuestionsArray", questionArrayWihoutEmptyQuestion);
            if (!localStorage.getItem("questionsArray")) {
              localStorage.setItem(
                "questionsArray",
                JSON.stringify(questionArrayWihoutEmptyQuestion)
              );
            }
            commit("setLoading", false);
          } else {
            ////////// IF NOT "SORTED", THEN NORMAL CONTINUATION
            dispatch("checkQuestionListToBeSent", {
              questions: questions,
              randomPointsOrder: randomPointsOrder,
            });
          }
          //******************************************************************************************* */
          //   if (getters.lang == "he") {
          //  var questions = data.val().fr.questions;
          //   } else {
          //     var questions = data.val().ar.questions;
          //   }

          // dispatch("checkQuestionListToBeSent", {
          //   questions: questions,
          //   randomPointsOrder: randomPointsOrder,
          // });

          // dispatch("checkQuestionListToBeSent", {
          //   questions: data.val().he.questions,
          //   randomPointsOrder: randomPointsOrder,
          // });
          //******************************************************** */
        });
    },
    getResultsByPoints({ dispatch, commit }, payload) {
      let arrayOfResults = [];
      const db = firebase.database();
      db.ref(payload + "/results")
        .orderByChild("errorNumber")
        .on("value", (resultsInfoOrderByChild) => {
          let index = 0;
          //   //console.log("resultsInfoOrderByChild", resultsInfoOrderByChild.val());
          resultsInfoOrderByChild.forEach(function(results) {
            var resultsOfTheGame = results.val();
            arrayOfResults.push(resultsOfTheGame);
          });
          //console.log("arrayOfResults", arrayOfResults);
          let newArraySorted = arrayOfResults.reverse();
          //console.log("newArraySorted", newArraySorted);
          newArraySorted.forEach(function(result) {
            index++;
            var resultsOfTheGame = result;
            arrayOfResults.push(resultsOfTheGame);
            commit("addResult", {
              resultsOfTheGame: result,
              place: index,
            });
          });
        });
    },
    // fetchSentence({
    //     commit,
    //     getters
    // }, payload) {
    //     setTimeout(_ => {
    //         let userColor = getters.user.groupColorEnglish
    //         if (userColor) {
    //             firebase.database().ref(payload.navigationName + '/sentence/' + userColor + 'Sentence').once('value')
    //                 .then(sentence => {
    //                     this.sentence = sentence.val()
    //                     let arrayOfLetters = Array.from(this.sentence)
    //                     // //console.log("[fetchSentence] arrayOfLetters", arrayOfLetters)
    //                     commit('setLettersArray', arrayOfLetters)
    //                 })
    //         } else {
    //             this.fetchSentence
    //         }
    //     }, 1000)
    // },

    storeSentence({ commit, getters }, payload) {
      let numberOfLetter = payload.sentence.length;
      let sentenceByFour = Math.round(numberOfLetter / 4);
      let redSentence = payload.sentence.slice(0, sentenceByFour);
      let royalblueSentence = payload.sentence.slice(
        sentenceByFour,
        sentenceByFour * 2
      );
      let darkgreenSentence = payload.sentence.slice(
        sentenceByFour * 2,
        sentenceByFour * 3
      );
      let purpleSentence = payload.sentence.slice(
        sentenceByFour * 3,
        numberOfLetter
      );
      firebase
        .database()
        .ref(payload.navigationName + "/sentence/redSentence")
        .set(redSentence);
      firebase
        .database()
        .ref(payload.navigationName + "/sentence/royalblueSentence")
        .set(royalblueSentence);
      firebase
        .database()
        .ref(payload.navigationName + "/sentence/darkgreenSentence")
        .set(darkgreenSentence);
      firebase
        .database()
        .ref(payload.navigationName + "/sentence/purpleSentence")
        .set(purpleSentence);
    },

    checkQuestionListToBeSent({ commit, dispatch, getters }, payload) {
      var questionArrayWihoutEmptyQuestion = [];
      // var arrayQuestion = payload.questions
      console.log(
        "Object.values(payload.questions)",
        Object.values(payload.questions)
      );
      var arrayQuestion = Object.values(payload.questions);
      arrayQuestion.forEach((question) => {
        if (question != null) {
          questionArrayWihoutEmptyQuestion.push(question);
        } else {
          console.log("this question is empty", question);
        }
      });
      if (getters.user) {
        if (getters.gameParameters.randomQuestions === false) {
          var questionArray = [];
          payload.randomPointsOrder.forEach((point) => {
            questionArrayWihoutEmptyQuestion.map((x) => {
              // if (x.questionNumber == point * 2 || x.questionNumber == point * 2 - 1) {
              //     questionArray.push(x)
              // } else {}
              if (x.pointLinked == point) {
                questionArray.push(x);
              } else {
              }
            });
          });
          commit("setQuestionsSuffled", questionArray);
          if (!localStorage.getItem("questionSuffled")) {
            localStorage.setItem(
              "questionSuffled",
              JSON.stringify(questionArray)
            );
          }
        } else {
          dispatch("suffleQuestions", questionArrayWihoutEmptyQuestion);
        }
      } else {
        ///////////////////CHANGED THE BELOW TO HEBREW VERSION
        //dispatch('checkQuestionListToBeSent', payload)
        /////////////////////////////////////////////////
        //console.log(
        //   "[checkQuestionListToBeSent] questionArrayWihoutEmptyQuestion",
        //   questionArrayWihoutEmptyQuestion
        // );

        dispatch("checkQuestionListToBeSent", questionArrayWihoutEmptyQuestion);
      }
      commit("setLoading", false);
    },

    suffleQuestions({ commit, dispatch }, payload) {
      this.questions = payload;
      commit("setQuestions", this.questions);
      let questionSuffled = this.questions;
      let currentIndex = questionSuffled.length,
        temporaryValue,
        randomIndex;
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = questionSuffled[currentIndex];
        questionSuffled[currentIndex] = questionSuffled[randomIndex];
        questionSuffled[randomIndex] = temporaryValue;
      }
      if (questionSuffled[0]) {
        commit("setQuestionsSuffled", questionSuffled);
        ////////////ADDED FROM THE HEBREW VERSION////////////////////
        if (!localStorage.getItem("questionSuffled")) {
          localStorage.setItem(
            "questionSuffled",
            JSON.stringify(questionSuffled)
          );
        }
        //////////////////////////////
        // commit('setLoading', false)
      } else {
        dispatch("suffleQuestions", payload);
      }
    },

    /////////////////////////////////////LANGUAGES////////////////////////////////
    // switchLangToFrench({ commit }) {
    //   commit("switchLang", "fr");
    // },
    // switchLangToEnglish({ commit }) {
    //   commit("switchLang", "en");
    // },

    /////////////////////////////////////LANGUAGES////////////////////////////////

    switchLanguage({ commit }, payload) {
      commit("switchLang", payload);
    },
  },
  getters: {
    questionsArray(state) {
      return state.questionsArray;
    },
    arrayOfImagesBase64(state) {
      return state.arrayOfImagesBase64;
    },
    arrayOfAudioUrl(state) {
      return state.arrayOfAudioUrl;
    },
    arrayOfPointInfo(state) {
      return state.arrayOfPointInfo;
    },
    lang(state) {
      return state.lang;
    },
    mapParameters(state) {
      return state.mapParameters;
    },
    welcomePage(state) {
      return state.welcomePage;
    },
    lettersArray(state) {
      return state.lettersArray;
    },
    gameParameters(state) {
      return state.gameParameters;
    },
    allNavGameNames(state) {
      return state.allNavGameNames;
    },
    loading(state) {
      // //console.log("[getters] loading => state.loading", state.loading)
      return state.loading;
    },
    results(state) {
      return state.results;
    },
    user(state) {
      return state.user;
    },
    evolutionPointNumber(state) {
      return state.evolutionPointNumber;
    },
    points(state) {
      return state.points;
    },
    markers(state) {
      return state.markers;
    },
    questions(state) {
      return state.questions;
    },
    questionsShuffled(state) {
      //console.log("[getters] state.questionsShuffled", state.questionsShuffled);
      return state.questionsShuffled;
    },
    group(state) {
      return state.group;
    },
    pointsArrayOrder(state) {
      return state.pointsArrayOrder;
    },
    questionsList(state) {
      return state.questionsList;
    },
    getUser(state) {
      return (userId) => {
        return state.users.find((user) => {
          return user.id === userId;
        });
      };
    },
  },
};
